import Vue from 'vue'
import Vuex from 'vuex'

// Vuex Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import activity from './activity'
import game from './game'
import onboarding from './onboarding'
import settime from './settime'
import company from './company'
import auth from './auth'
import media from './media'
import content from './content'
import project from './project'
import user from './user'
import report from './report'
import group from './group'
import task from './task'
import goal from './goal'
import kpi from './kpi'
import dashboard from './dashboard'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    activity,
    game,
    onboarding,
    settime,
    company,
    auth,
    media,
    content,
    project,
    user,
    report,
    group,
    task,
    goal,
    kpi,
    dashboard,
  },
})
