export default [
  {
    path: '/content/list',
    name: 'content-list',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/content/content-list/ContentList.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Content List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/content-configuration',
    name: 'content-configuration',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/content/content-configuration/ContentConfiguration.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Content Configuration',
          active: true,
        },
      ],
    },
  },
  {
    path: '/content/content-configuration/:id',
    name: 'content-configuration-id',
    // eslint-disable-next-line import/no-unresolved
    component: () => import('@/views/content/content-configuration/ContentConfiguration.vue'),
    meta: {
      pageTitle: 'Content',
      breadcrumb: [
        {
          text: 'Content Edit',
          active: true,
        },
      ],
    },
  },
]
